import React from 'react';
import './SearchCategory.css';

const SearchCategory = ({ categories, onCategoryClick }) => {
  return (
    <div className="search-category-container">
      {/* {categories.map((category, index) => (
        <div className={`search-category-grid-item item-${index}`} key={category.code}>
          <img src={category.img} alt={category.title} />
          <div className="search-category-text">{category.title}</div>
        </div>
      ))} */}

      <div className="search-category-col1">
        <div className="search-category-col1-row1">
          <div className="search-category-col1-row1-col1">
            <div
              className={`search-category-grid-item`}
              key={categories[0].code}
              onClick={() => onCategoryClick(categories[0])}
            >
              <img src={categories[0].img} alt={categories[0].title} />
              <div className="search-category-text">{categories[0].title}</div>
            </div>
          </div>
          <div className="search-category-col1-row1-col2">
            <div
              className={`search-category-grid-item`}
              key={categories[1].code}
              onClick={() => onCategoryClick(categories[1])}
            >
              <img src={categories[1].img} alt={categories[1].title} />
              <div className="search-category-text">{categories[1].title}</div>
            </div>
          </div>
        </div>
        <div className="search-category-col1-row2">
          <div
            className={`search-category-grid-item`}
            key={categories[2].code}
            onClick={() => onCategoryClick(categories[2])}
          >
            <img src={categories[2].img} alt={categories[2].title} />
            <div className="search-category-text">{categories[2].title}</div>
          </div>
        </div>
      </div>
      <div className="search-category-col2">
        <div
          className={`search-category-grid-item`}
          key={categories[3].code}
          onClick={() => onCategoryClick(categories[3])}
        >
          <img src={categories[3].img} alt={categories[3].title} />
          <div className="search-category-text">{categories[3].title}</div>
        </div>
      </div>
    </div>
  );
};

export default SearchCategory;
