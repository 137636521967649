import React, { useState, useEffect, useRef } from "react";
import "./Products.css";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

// Import components
import ProductItem from "../../components/v2/ProductItemProducts";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import FiltersComponent from "../../components/v2/Filters";
import NavbarDesktop from "../../components/desktop/NavbarDesktop";
import FooterDesktop from "../../components/desktop/FooterDesktop";

// Icons
import ProductsView from "../../img/products-view.svg";
import ProductsViewSecond from "../../img/products-view-second.svg";
import Filters from "../../img/filters.svg";
import FiltersClose from "../../img/filters-close.svg";

import productsWithoutScore from "../../pages/v2/jsons/products_without_score.json"; // Import the json


const Products = ({ productList, hasTokenEvent }) => {
  const [viewInLine, setViewInLine] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const productItemsRef = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Para o componente Filters
  const [productId, setProductId] = useState("");
  const [ScoreRange, setScoreRange] = useState([0, 100]);
  const [convertedRange, setConvertedRange] = useState();
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [selectedBrandsFilters, setSelectedBrandsFilters] = useState([]);
  const [selectedTypesFilters, setSelectedTypesFilters] = useState([]);
  const [selectedGradesFilters, setSelectedGradesFilters] = useState([]);




  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handlePageShow = () => {
      const scrollableElement = productItemsRef.current;

      if (scrollableElement) {
        scrollableElement.classList.add("scrollable");
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  // CASO EXISTA TOKEN
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("expositionMude")) {
      const expositionMude = params.get("expositionMude");
      if (expositionMude === "true") {
        const storedToken = JSON.parse(localStorage.getItem("authToken"));
        const newToken = generateToken();
        hasTokenEvent(true, expirationDate);
        params.delete("expositionMude");
        navigate(`${location.pathname}?${params.toString()}`, {
          replace: true,
        });
      }
    } else {
      /*    localStorage.removeItem("authToken"); */
      /*   console.log("Parâmetro expositionMude não encontrado."); */
    }
  }, []);

  // <-----------------------GERAR TOKEN CASO PRODUTO SEJA DA LISTA------------------------------------------>
  const startDate = new Date();
  const expirationDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    23,
    59,
    59,
    999
  );
  /*   const expirationDate = new Date(startDate.getTime() + 2 * 60 * 1000);  */
  const generateToken = () => {
    const token = {
      value: "your-unique-token",
      startDate: startDate.getTime(),
      expiration: expirationDate.getTime(),
    };

    localStorage.setItem("authToken", JSON.stringify(token));
    return token;
  };

  const isTokenValid = (token) => {
    const currentTime = new Date().getTime();
    return token && token.expiration > currentTime; // Verifica se o token existe e ainda não expirou
  };
  // <-------------------------------------------token----------------->

  const handleClickFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleCloseMenuApplyFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleGetFilters = (convertedRange, selectedBrands, selectedTypes) => {
    setHasFilters(true);
    setConvertedRange(convertedRange);
    setScoreRange(convertedRange || [0, 100]);
    setSelectedBrands(selectedBrands || []);
    setSelectedTypes(selectedTypes || []);
  };

// Check if the ScoreRange is not [0, 100]
const isScoreRangeAltered = !(ScoreRange[0] === 0 && ScoreRange[1] === 100);

// Filtered products
const filteredProducts = productList.filter((product) => {
  const searchBoxCondition = product.id
    .toString()
    .toLowerCase()
    .includes(productId.toLowerCase());

  // Apply the condition for excluding products based on ScoreRange and whether a grade is selected
  const gradeCondition =
    isScoreRangeAltered && selectedGradesFilters.length > 0
      ? product.dpp.type !== 'DPP_TYPE_NO_SCORE'
      : isScoreRangeAltered && !selectedGradesFilters.length
      ? product.dpp.type !== 'DPP_TYPE_NO_SCORE'
      : !isScoreRangeAltered && !selectedGradesFilters.length && convertedRange
      ? product.dpp.type !== 'DPP_TYPE_NO_SCORE'
      : convertedRange && selectedGradesFilters.length > 0
      ? product.dpp.type !== 'DPP_TYPE_NO_SCORE'
      : true;

  // Apply the ScoreRange filtering if it's not the default
  const scoreCondition =
    ScoreRange[0] === 0 && ScoreRange[1] === 100
      ? true // If ScoreRange is [0, 100], no score filtering
      : product.score >= ScoreRange[0] && product.score <= ScoreRange[1];

  const brandCondition = selectedBrands.length
    ? selectedBrands.includes(product.brand)
    : true;

  const typeCondition = selectedTypes.length
    ? selectedTypes.includes(product.type)
    : true;

  return (
    searchBoxCondition &&
    scoreCondition &&
    brandCondition &&
    typeCondition &&
    gradeCondition // Only include products that pass the grade condition
  );
});


  useEffect(() => {
    const uniqueBrands = [
      ...new Set(
        productList
          .map((product) => product.brand)
          .filter((brand) => brand !== undefined && brand !== null && brand !== "")
      ),
    ];
    setBrands(uniqueBrands);
  
    const uniqueTypes = [
      ...new Set(
        productList
          .map((product) => product.type)
          .filter((type) => type !== undefined && type !== null && type !== "")
      ),
    ];
    setTypes(uniqueTypes);
  }, [productList]);
  

  return isMobile ? (
    <div className="page-products-container">
      <div className="top-info">
        <div className="title">Products</div>
        <div className="filters-and-group-by">
          <div className="filters" onClick={handleClickFilters}>
            Filters
            <img src={showFilters ? FiltersClose : Filters} alt="Filter Icon" />
          </div>
          <div className="group-by">
            <div
              className={`icon ${!viewInLine ? "active" : ""}`}
              onClick={() => setViewInLine(false)}
            >
              <img src={ProductsView} alt="Grid View" />
            </div>
            <div
              className={`icon-second ${viewInLine ? "active" : ""}`}
              onClick={() => setViewInLine(true)}
            >
              <img src={ProductsViewSecond} alt="List View" />
            </div>
          </div>
        </div>
        {showFilters && (
          <FiltersComponent
            selectedBrands={selectedBrandsFilters}
            setSelectedBrands={setSelectedBrandsFilters}
            selectedTypes={selectedTypesFilters}
            setSelectedTypes={setSelectedTypesFilters}
            selectedGrades={selectedGradesFilters}
            setSelectedGrades={setSelectedGradesFilters}
            brands={brands}
            types={types}
            onFiltersChange={handleGetFilters}
            onApplyFilters={handleCloseMenuApplyFilters}
            initialScoreRange={ScoreRange}
          />
        )}
      </div>
      <div
        className={
          viewInLine ? "page-products-content-view" : "page-products-content"
        }
      >
        <div
          className={
            viewInLine
              ? `page-product-items-inline`
              : `page-product-items${
                  filteredProducts.length === 0 ? " empty" : ""
                }`
          }
          ref={productItemsRef}
        >
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div
                className={
                  !viewInLine ? "page-product-item-inline" : "page-product-item"
                }
                key={product.id}
              >
                <ProductItem productObject={product} />
              </div>
            ))
          ) : (
            <div className="no-products-message">
              No products found. Please adjust your search criteria.
            </div>
          )}
        </div>
      </div>
      <div className="navbar-footer">
        <Navbar MenuClick={() => setMenuOpen(true)} />
        <OverlayComponent
          isOpen={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        />
      </div>
    </div>
  ) : (
    <div className="page-products-container-desktop">
      <div className="page-products-navbar">
        {" "}
        <NavbarDesktop></NavbarDesktop>
      </div>

      <div className="page-products-footer-content-desktop">
        <div className="page-products-content-desktop">
          <div className="container-desktop-page-products">
            <div className="top-info-desktop">
              <div className="filters-and-group-by-desktop">
                <div className="filters-desktop" onClick={handleClickFilters}>
                  Filters
                  <img
                    src={showFilters ? FiltersClose : Filters}
                    alt="Filter Icon"
                  />
                </div>
              </div>
              {showFilters && (
                <FiltersComponent
                  selectedBrands={selectedBrandsFilters}
                  setSelectedBrands={setSelectedBrandsFilters}
                  selectedTypes={selectedTypesFilters}
                  setSelectedTypes={setSelectedTypesFilters}
                  selectedGrades={selectedGradesFilters}
                  setSelectedGrades={setSelectedGradesFilters}
                  brands={brands}
                  types={types}
                  onFiltersChange={handleGetFilters}
                  onApplyFilters={handleCloseMenuApplyFilters}
                  initialScoreRange={ScoreRange}
                />
              )}
            </div>
            <div
              className={`page-product-items-desktop ${
                filteredProducts.length === 0 ? " empty" : ""
              }`}
              ref={productItemsRef}
            >
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  <div
                    className={
                      !viewInLine
                        ? "page-product-item-inline"
                        : "page-product-item"
                    }
                    key={product.id}
                  >
                    <ProductItem productObject={product} />
                  </div>
                ))
              ) : (
                <div className="no-products-message">
                  No products found. Please adjust your search criteria.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-footer-products">
        <FooterDesktop />
      </div>
    </div>
  );
};

export default Products;
